<template>
  <div class="comportamental-component">
    <v-card flat class="mt-0" elevation="0">
      <v-card-text>
        <Crud
          :title="'Comportamental'"
          buttonAddItemText="Nova competência"
          :tamanhoModalCrud="1000"
          searchLabelText="Pesquisar na lista"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :loadingButtonSave="loadingButtonSave"
        >
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="competencias"
              :loading="isLoadingTable"
              class="table-kitchen-sink"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              loading-text="Carregando... Por favor, espere"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }"
              mobile-breakpoint="0"
            >
              <template v-slot:header.id_avaliacao="{ header }">
                {{ header.text }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" dark v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    Mostra as competências que são existentes somente nesta
                    avaliação, e competências cadastradas no sistema.
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="error">
                  <span class="text-white">Nenhum registro encontrado</span>
                </v-alert>
              </template>
              <!-- COMPORTAMENTOS -->
              <template v-slot:[`item.id_avaliacao`]="{ item }">
                <v-chip :color="item.id_avaliacao ? 'warning' : 'info'">
                  {{ item.id_avaliacao ? "Sim" : "Não" }}
                </v-chip>
              </template>
              <!-- NIVEIS -->
              <template v-slot:[`item.id_nivel`]="{ item }">
                <span v-if="item.id_nivel">
                  {{ getNivel(item.id_nivel).descricao | capitalize() }}
                </span>
                <span v-else> Não possui </span>
              </template>
              <!-- CREATED_AT -->
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <!-- UPDATED_AT -->
              <template v-slot:[`item.updated_at`]="{ item }">
                <span
                  >{{ item.updated_at | dateFormatBr() }}
                  {{ item.updated_at | timeFormatBr() }}h</span
                >
              </template>
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="editar(item)"
                            >
                              <v-icon class="actions"> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </template>
          <template slot="contentModalCrud">
            <v-container fluid>
              <CompetenciaForm />
            </v-container>
          </template>
        </Crud>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import Crud from "@/components/Crud.vue";
import CompetenciaForm from "./CompetenciaForm.vue";
import { onMounted, ref, computed } from "@vue/composition-api";

export default {
  inject: ["$validator"],
  name: "comportamental-Component",
  components: { Crud, CompetenciaForm },
  setup(_, { emit }) {
    // Lifecycle
    onMounted(async () => {
      isLoadingTable.value = true;

      const data = {
        columns: [
          "uuid",
          "descricao",
          "peso",
          "id_nivel",
          "id_avaliacao",
          "created_at",
          "updated_at",
        ],
        validate: [["id_avaliacao", avaliacao.value.id]],
        orValidate: [["id_avaliacao", null]],
      };

      await store.dispatch("avdNiveis/initialize");
      await store.dispatch("avdCompetencias/findByColumns", data);

      isLoadingTable.value = false;
    });

    // Variables
    const nameForm = ref("Nova competência");
    const modalLateral = ref({
      show: false,
      item: [],
      title: "",
    });
    const headers = ref([
      { text: "Competencia", value: "descricao" },
      { text: "Níveis", value: "id_nivel" },
      { text: "Da avaliação", value: "id_avaliacao", align: "center" },
      { text: "Data criação", value: "created_at" },
      { text: "Data atualização", value: "updated_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ]);
    const headerModalLateral = ref([
      { text: "Escalas", value: "escala" },
      { text: "Graus de avaliação", value: "graus" },
      { text: "", value: "acoes" },
    ]);
    const dialog = ref(false);
    const isLoadingTable = ref(false);
    const loadingButtonSave = ref(false);

    // Computed
    const competencia = computed({
      get() {
        return store.getters["avdCompetencias/getCompetencia"];
      },
      set(value) {
        store.dispatch("avdCompetencias/setCompetencia", value);
      },
    });
    const competencias = computed(
      () => store.getters["avdCompetencias/getCompetencias"]
    );
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const niveis = computed(() => store.getters["avdNiveis/getNiveis"]);

    // Function
    function validate() {
      return true;
    }
    function salvar() {
      let result = false;

      if (
        competencia.value.descricao != "" &&
        competencia.value.comportamentos.length >= 1 &&
        competencia.value.comportamentos[0].descricao != ""
      ) {
        result = true;
      }

      if (result) {
        const isEdit = competencia.value.id != undefined ? 1 : -1;
        loadingButtonSave.value = true;

        let url =
          isEdit === -1
            ? "/avd/competencia"
            : `/avd/competencia/${competencia.value.uuid}`;
        let method = isEdit === -1 ? "POST" : "PUT";
        let sucesso =
          isEdit === -1
            ? "Competência cadastrado com sucesso."
            : "Competência atualizado com sucesso.";
        let erro =
          isEdit === -1
            ? "Erro ao salvar competência."
            : "Erro ao atualizar competência.";

        let competenciaItem = competencia.value;
        competenciaItem.id_avaliacao = avaliacao.value.id;
        competenciaItem._method = method;
        competenciaItem.url = url;

        store
          .dispatch("avdCompetencias/save", competenciaItem)
          .then((response) => {
            if (response != "NAO_AUTORIZADO") {
              if (isEdit === -1) {
                competencias.value.push(response);
              } else {
                const index = competencias.value.findIndex(
                  (el) => el.id == competenciaItem.id
                );

                if (competenciaItem.nivel) {
                  const niveis = store.getters["avdNiveis/getNiveis"];

                  const newNivel = niveis.find(
                    (el) => el.id == competenciaItem.id_nivel
                  );

                  competenciaItem.nivel = newNivel;
                }

                Object.assign(competencias.value[index], competenciaItem);
              }
              store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: sucesso,
              });
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "danger",
              timeout: 3000,
              text: erro,
            });
          })
          .finally(() => {
            loadingButtonSave.value = false;
            fechar();
          });
      }
    }
    function editar(item) {
      isLoadingTable.value = true;

      store
        .dispatch("avdCompetencias/findByUuid", item.uuid)
        .then(() => {
          dialog.value = true;
        })
        .catch(() => {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 5000,
            text: "Ocorreu um erro ao buscar pelos dados da competência. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
          });
        })
        .finally(() => {
          isLoadingTable.value = false;
        });
    }
    function fechar() {
      dialog.value = false;
      competencia.value = {
        id_cliente: null,
        id_nivel: null,
        descricao: "",
        peso: 0,
        ids_cargos: [],
        ids_setores: [],
        comportamentos: [
          {
            descricao: "",
          },
        ],
      };
    }
    function getNivel(id = Number) {
      return niveis.value.find((el) => el.id == id);
    }

    return {
      // Variables
      nameForm,
      modalLateral,
      headers,
      headerModalLateral,
      dialog,
      isLoadingTable,
      loadingButtonSave,

      // Computed
      competencia,
      competencias,
      avaliacao,
      niveis,

      // Function
      validate,
      salvar,
      editar,
      fechar,
      getNivel,
    };
  },
};
</script>

<style lang="scss"></style>
