import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comportamental-component"},[_c(VCard,{staticClass:"mt-0",attrs:{"flat":"","elevation":"0"}},[_c(VCardText,[_c('Crud',{attrs:{"title":'Comportamental',"buttonAddItemText":"Nova competência","tamanhoModalCrud":1000,"searchLabelText":"Pesquisar na lista","formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"loadingButtonSave":_vm.loadingButtonSave},on:{"update:dialog":function($event){_vm.dialog=$event}}},[_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.competencias,"loading":_vm.isLoadingTable,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","footer-props":{
              showFirstLastPage: true,
              'items-per-page-text': 'Linhas por página',
            },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"header.id_avaliacao",fn:function(ref){
            var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Mostra as competências que são existentes somente nesta avaliação, e competências cadastradas no sistema. ")])])]}},{key:"no-data",fn:function(){return [_c(VAlert,{attrs:{"value":true,"color":"error"}},[_c('span',{staticClass:"text-white"},[_vm._v("Nenhum registro encontrado")])])]},proxy:true},{key:"item.id_avaliacao",fn:function(ref){
            var item = ref.item;
return [_c(VChip,{attrs:{"color":item.id_avaliacao ? 'warning' : 'info'}},[_vm._v(" "+_vm._s(item.id_avaliacao ? "Sim" : "Não")+" ")])]}},{key:"item.id_nivel",fn:function(ref){
            var item = ref.item;
return [(item.id_nivel)?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getNivel(item.id_nivel).descricao))+" ")]):_c('span',[_vm._v(" Não possui ")])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at))+"h")])]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.updated_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.updated_at))+"h")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1)],1)],1)]}}],null,true)})],1),_c('template',{slot:"contentModalCrud"},[_c(VContainer,{attrs:{"fluid":""}},[_c('CompetenciaForm')],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }